// chat window scss
@import '~@Green-Dot-Corporation/eureka-lib-style-utils/src/styles/mixins/layout.scss';
@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/utils/theme-utils.scss';

.web-chat-text-bubble {
    $this: &;

    &__bubble-actions-container {
        margin: 8px 5px;
    }

    &__bubble-actions-container::after {
        content: '';
        clear: both;
        display: table;
    }

    &__bubble-container {
        max-width: var(--chat-bubble-max-width, 266px);
        border-radius: var(--eureka-chat-bubble-border-radius, #{eureka-light-theme('chat-bubble-border-radius')});
        padding: 0.8rem 1.2rem;
        position: relative;
        word-break: break-word;
        white-space: pre-line;
    }

    &__tail {
        position: absolute;
        display: block;
        width: #{eureka-to-px(eureka-light-theme('core-system-scale') * 3)};
        height: #{eureka-to-px(eureka-light-theme('core-system-scale') * 4)};
        bottom: 0;
    }

    &--from-me {
        @include eureka-flex-row-reverse;

        #{$this}__bubble-container {
            background-color: var(--eureka-chat-bubble-sender-color-bg, #{eureka-light-theme('core-color-primary')});
            color: var(--eureka-chat-bubble-sender-color-text, #{eureka-light-theme('core-color-canvas')});
        }

        #{$this}__tail {
            right: -6px;
            color: var(--eureka-chat-bubble-sender-color-bg, #{eureka-light-theme('core-color-primary')});
        }
    }

    &--from-other {
        @include eureka-flex-row;

        #{$this}__bubble-container {
            background-color: var(--eureka-core-color-canvas-alt, #{eureka-light-theme('core-color-canvas-alt')});
            color: var(--eureka-core-color-text, #{eureka-light-theme('core-color-text')});
        }

        #{$this}__tail {
            left: -5px;
            color: var(--eureka-core-color-canvas-alt, #{eureka-light-theme('core-color-canvas-alt')});
        }
    }

    &__actions-container {
        float: right;
        overflow: auto;
        font-weight: var(--eureka-core-typography-font-weight, #{eureka-light-theme('core-typography-font-weight')});
        font-size: var(--eureka-core-typography-footnote-font-size, #{eureka-to-rem(eureka-light-theme('core-typography-footnote-font-size'))});

        #{$this}__actions-text {
            color: var(--eureka-core-color-error, #{eureka-light-theme('core-color-error')});
        }

        #{$this}__actions-link-retry {
            margin-left: 7px;
        }

        #{$this}__actions-divider {
            margin-left: 2.5px;
            margin-right: 2.5px;
        }
    }
}
