@import '~@Green-Dot-Corporation/eureka-lib-style-utils/src/styles/mixins/layout.scss';
@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/mixins/typography.scss';
@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/utils/theme-utils.scss';

.web-chat-loading {
    .eureka-tile__content-container {
        @include eureka-flex-column;
    }

    .eureka-tile__content-container-inner {
        margin: auto;
    }

    .eureka-callout__title {
        @include eureka-h5;
        margin-top: #{eureka-to-px(eureka-light-theme('core-system-scale') * 6)};
    }
}
