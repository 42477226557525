@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/utils/theme-utils.scss';

.web-chat-generic-message {
    $this: &;

    .eureka-tile__content-container {
        height: 362px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__message-content {
        padding: 0;

        .eureka-callout__title {
            font-size: var(--eureka-core-typography-headings-level5-font-size, #{eureka-to-rem(eureka-light-theme('core-typography-headings-level5-font-size'))});
        }
    }
}
