@import '~@Green-Dot-Corporation/eureka-lib-style-utils/src/styles/mixins/layout.scss';
@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/utils/theme-utils.scss';

.web-chat-leave-message {
    $this: &;

    .eureka-tile__content-container {
        @include eureka-flex-row;
        justify-content: center;
        align-items: center;
    }

    &__message-input {
        display: block;
        margin-bottom:
            #{eureka-to-px(
                eureka-light-theme('core-system-scale') * 2
            )};
        margin-top: #{eureka-to-px(eureka-light-theme('core-system-scale') * 4)};

        .web-chat-message-input.web-chat-textarea-autosize {
            width: 85%;
            margin-top:
                #{eureka-to-px(
                    eureka-light-theme('core-system-scale') * 6
                )};
            padding-top: 0;
        }

        .web-chat-textarea-autosize::-webkit-scrollbar {
            display: none;
        }
    }

    &__after-control-container {
        @include eureka-flex-column;
        justify-content: flex-end;
        flex: 0 0 auto;
        align-items: flex-end;
        position: absolute;
        top: 7px;
        right: 8px;
    }

    &__counter-container {
        font-size: var(--eureka-core-typography-sub-text-font-size, #{eureka-to-rem(eureka-light-theme('core-typography-sub-text-font-size'))});
        color: var(--eureka-form-input-placeholder-color-text, #{eureka-light-theme('form-input-placeholder-color-text')});
    }

    &__leave-message-content {
        .eureka-callout {
            padding: 0;
            margin-bottom:
                #{eureka-to-px(
                    eureka-light-theme('core-system-scale') * 2
                )};

            &__title {
                font-size:
                    var(
                        --eureka-core-typography-headings-level5-font-size
                    );
            }
        }

        #{$this}__email-input {
            width: 100%;

            input {
                width: 100%;
            }
        }

        #{$this}__error-msg {
            font-size: var(--eureka-core-typography-footnote-font-size, #{eureka-to-rem(eureka-light-theme('core-typography-footnote-font-size'))});
            text-align: center;
            margin-top: 0;
            margin-bottom:
                #{eureka-to-px(
                    eureka-light-theme('core-system-scale') * 2
                )};
            color: var(--eureka-core-color-error, #{eureka-light-theme('core-color-error')});
        }

        #{$this}__send-msg-btn {
            width: 100%;

            .eureka-button__text {
                font-size: var(--eureka-core-typography-large-font-size, #{eureka-to-rem(eureka-light-theme('core-typography-large-font-size'))});
            }
        }

        #{$this}__msg-input-hint {
            // TODO: Hardcode the font size here, since the legacy and prelogin sites currently do not support scalability
            // font-size: var(--eureka-core-typography-sub-text-font-size, #{eureka-to-rem(eureka-light-theme('core-typography-sub-text-font-size'))});
            font-size: 13px;
            margin-top: 0;
            margin-bottom:
                #{eureka-to-px(
                    eureka-light-theme('core-system-scale') * 4
                )};
            color: var(--eureka-core-color-text-alt, #{eureka-light-theme('core-color-text-alt')});
        }
    }
}
