// chat window scss
@import '~@Green-Dot-Corporation/eureka-lib-style-utils/src/styles/mixins/layout.scss';
@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/utils/theme-utils.scss';

.web-chat-typing-bubble {
    @include eureka-flex-row;
    margin: 8px 5px;
    $this: &;

    &__bubble-container {
        max-width: var(--chat-bubble-max-width, 266px);
        border-radius: var(--eureka-chat-bubble-border-radius, #{eureka-light-theme('chat-bubble-border-radius')});
        padding: 0.8rem 1.2rem;
        position: relative;
    }

    &__tail {
        position: absolute;
        display: block;
        width: 12px;
        height: 15px;
        bottom: 0;
    }

    .eureka-loading-dots__dot {
        width: 0.15em;
        height: 0.15em;
        background-color: var(--eureka-core-color-universal-accessible-border, #{eureka-light-theme('core-color-universal-accessible-border')});
    }

    #{$this}__bubble-container {
        background-color: var(--eureka-core-color-canvas-alt, #{eureka-light-theme('core-color-canvas-alt')});
        color: var(--eureka-core-color-text, #{eureka-light-theme('core-color-text')});
    }

    #{$this}__tail {
        left: -5px;
        color: var(--eureka-core-color-canvas-alt, #{eureka-light-theme('core-color-canvas-alt')});
    }
}
