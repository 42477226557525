// icon button chat scss
@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/utils/theme-utils.scss';
@import '../../../styles/mixin/layout.scss';

.icon-button-chat {
    position: absolute;
    right: 80px;
    width: #{eureka-to-px(eureka-light-theme('core-system-scale') * 16)};

    &__container {
        position: fixed;
        z-index: 999999;
        bottom: 25px;
    }

    &__icon-btn-chat.eureka-button {
        width: #{eureka-to-px(eureka-light-theme('core-system-scale') * 16)};
        height: #{eureka-to-px(eureka-light-theme('core-system-scale') * 16)};
        border-radius: var(--eureka-chat-floating-btn-border-radius, #{eureka-light-theme('chat-floating-btn-border-radius')});
        border-color: var(--eureka-chat-floating-btn-color-border, #{eureka-light-theme('chat-floating-btn-color-border')});
        box-shadow: 0 0 10px rgba(0, 0, 0, 25%);
        color: var(--eureka-core-color-primary, #{eureka-light-theme('core-color-primary')});
        font-size: var(--eureka-core-typography-sub-text-font-size, #{eureka-to-rem(eureka-light-theme('core-typography-sub-text-font-size'))});
    }

    &__icon-btn-chat.eureka-button:hover {
        border-color: var(--eureka-chat-floating-btn-hover-color-border, #{eureka-light-theme('chat-floating-btn-hover-color-border')});
    }

    .eureka-button__icon-container,
    .eureka-button__text-container {
        padding: 0;
    }

    .eureka-button__icon-safe-space {
        width: auto;
    }

    &__count {
        position: absolute;
        top: 0;
        right: 4px;
        width: 14px;
        height: 14px;
        text-align: center;
        border-radius: 100%;
        background: var(--eureka-core-color-destructive, #{eureka-light-theme('core-color-destructive')});
    }

    &__count[data-count='0'] {
        display: none;
    }

    &__count-number {
        width: #{eureka-to-px(eureka-light-theme('core-system-scale') * 3)};
        height: #{eureka-to-px(eureka-light-theme('core-system-scale') * 3)};
    }
}

@include eureka-breakpoint-mobile {
    .icon-button-chat {
        right: 25px;
    }
}

@include eureka-breakpoint-mobile(landscape) {
    .icon-button-chat {
        right: 25px;
    }
}