// chat window scss
@import '~@Green-Dot-Corporation/eureka-lib-style-utils/src/styles/mixins/layout.scss';
@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/mixins/states.scss';
@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/utils/theme-utils.scss';

.web-chat-image-bubble {
    margin: 8px 3px;
    $this: &;

    &__loading-container::after {
        content: '';
        clear: both;
        display: table;
    }

    &__loading-container {
        max-width: var(--chat-bubble-max-width, 266px);
        font-size: var(--eureka-core-typography-footnote-font-size, #{eureka-to-rem(eureka-light-theme('core-typography-footnote-font-size'))});
        border-radius: var(--eureka-chat-bubble-border-radius, #{eureka-light-theme('chat-bubble-border-radius')});
        padding-top: 0.8rem;
        text-align: center;
        position: relative;

        #{$this}__loading-bubble {
            @include eureka-flex-row;
            background-color: var(--eureka-core-color-canvas-alt, #{eureka-light-theme('core-color-canvas-alt')});
            color: var(--eureka-core-color-text, #{eureka-light-theme('core-color-text')});
            width: 95px;
            height: 56px;
            align-items: center;
            justify-content: center;
            border-radius: var(--eureka-chat-bubble-border-radius, #{eureka-light-theme('chat-bubble-border-radius')});
            position: relative;
            float: right;

            .web-chat-svg-image svg {
                width: 33px;
                height: 26px;
            }

            #{$this}__tail {
                position: absolute;
                display: block;
                width: 12px;
                height: 15px;
                bottom: 0;
            }
        }

        #{$this}__uploading-status {
            font-size: var(--eureka-core-typography-footnote-font-size, #{eureka-to-rem(eureka-light-theme('core-typography-footnote-font-size'))});
            color: var(--eureka-core-color-text-alt, #{eureka-light-theme('core-color-text-alt')});
        }

        #{$this}__loading-image-from-other-status {
            font-size: var(--eureka-core-typography-footnote-font-size, #{eureka-to-rem(eureka-light-theme('core-typography-footnote-font-size'))});
            margin-top: 5px;
            margin-bottom: 2px;
        }
    }

    &__bubble-thumbnail-container {
        @include eureka-focus-state-halo(var(--eureka-tabs-active-focus-halo-color, #{eureka-light-theme('tabs-active-focus-halo-color')}));
        max-width: 100px;
        max-height: 133px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        border-radius: var(--eureka-chat-bubble-border-radius, #{eureka-light-theme('chat-bubble-border-radius')});
        box-shadow: 0 0 0 0.2rem transparent;

        #{$this}__thumbnail-container,
        #{$this}__thumbnail {
            border-radius: var(--eureka-chat-bubble-border-radius, #{eureka-light-theme('chat-bubble-border-radius')});
        }

        #{$this}__modal-scrim {
            @include eureka-flex-row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            min-height: 6rem;
            border-radius: var(--eureka-chat-bubble-border-radius, #{eureka-light-theme('chat-bubble-border-radius')});
            position: absolute;
            top: 0;
            color: transparent;
            text-align: center;
        }

        #{$this}__modal-scrim:hover {
            color: var(--eureka-core-color-canvas, #{eureka-light-theme('core-color-canvas')});
            background-color: var(--eureka-core-color-universal-focus-halo, rgba(0, 0, 0, 40%));
        }
    }

    &__bubble-thumbnail-container:focus-visible {
        #{$this}__modal-scrim {
            color: var(--eureka-core-color-canvas, #{eureka-light-theme('core-color-canvas')});
            background-color: var(--eureka-core-color-universal-focus-halo, rgba(0, 0, 0, 40%));
        }
    }

    &__thumbnail-container {
        @include eureka-flex-column;
        min-height: 60px;
        justify-content: center;
        border-radius: var(--eureka-chat-bubble-border-radius, #{eureka-light-theme('chat-bubble-border-radius')});
    }

    &--from-me {
        @include eureka-flex-row-reverse;

        #{$this}__bubble-loading-container {
            background-color: var(--eureka-core-color-primary, #{eureka-light-theme('core-color-primary')});
            color: var(--eureka-core-color-canvas, #{eureka-light-theme('core-color-canvas')});
        }

        #{$this}__loading-bubble {
            background-color: var(--eureka-core-color-primary, #{eureka-light-theme('core-color-primary')});
            color: var(--eureka-core-color-canvas, #{eureka-light-theme('core-color-canvas')});
        }

        #{$this}__tail {
            right: -6px;
            color: var(--eureka-core-color-primary, #{eureka-light-theme('core-color-primary')});
        }
    }

    &--from-other {
        @include eureka-flex-row;

        #{$this}__loading-bubble {
            @include eureka-flex-column;
            color: var(--eureka-core-color-text-alt, #{eureka-light-theme('core-color-text-alt')});
            width: 100px;
            height: 133px;
            float: left;

            .web-chat-svg-image {
                width:
                    #{eureka-to-rem(
                        eureka-light-theme('core-system-scale') * 5
                    )};
                height:
                    #{eureka-to-rem(
                        eureka-light-theme('core-system-scale') * 4
                    )};
            }
        }

        #{$this}__tail {
            left: -5px;
            color: var(--eureka-core-color-canvas-alt, #{eureka-light-theme('core-color-canvas-alt')});
        }

        #{$this}__actions-container {
            padding-left:
                #{eureka-to-rem(
                    eureka-light-theme('core-system-scale') * 4
                )};
        }
    }

    &__actions-container {
        float: right;
        overflow: auto;
        font-weight: var(--eureka-core-typography-font-weight, #{eureka-light-theme('core-typography-font-weight')});
        font-size: var(--eureka-core-typography-footnote-font-size, #{eureka-to-rem(eureka-light-theme('core-typography-footnote-font-size'))});

        #{$this}__actions-text {
            color: var(--eureka-core-color-error, #{eureka-light-theme('core-color-error')});
        }

        #{$this}__actions-link-retry {
            margin-left: 7px;
        }

        #{$this}__actions-divider {
            margin-left: 2.5px;
            margin-right: 2.5px;
        }
    }
}
