@import '~@Green-Dot-Corporation/eureka-lib-style-utils/src/styles/mixins/layout.scss';
@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/mixins/typography.scss';
@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/utils/theme-utils.scss';
@import '../../../styles/mixin/layout.scss';

.web-chat-window-container {
    position: absolute;
    right: 80px;
    bottom: 0;
    width: 100%;
    max-width: 375px;
    z-index: 999999;

    &__tile.eureka-tile {
        @include eureka-flex-column;
        z-index: 100;
        position: fixed;
        bottom: 0;
        width: 100%;
        max-width: 375px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 40%);
        border-radius: var(--eureka-chat-window-border-radius, 8px);

        .eureka-tile__content-container {
            height: 100%;
            min-height: 290px;
        }
    }

    &__tile-header {
        @include eureka-flex-row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        $this: &;

        #{$this}-title {
            @include eureka-h5;
            flex: 1 0 auto;
        }
    }

    &__title-btn-container {
        .eureka-context-icon {
            height: auto;
            width: auto;
        }

        .eureka-icon {
            height: 2.4rem;
            width: 2.4rem;
        }
    }

    &__title-btn-container--minimize-btn-container {
        margin-right:
            #{eureka-to-px(
                eureka-light-theme('core-system-scale') * 4
            )};
    }

    .eureka-tile__heading-container {
        @include eureka-flex-row;
        height: #{eureka-to-px(eureka-light-theme('core-system-scale') * 14)};
        border-bottom: none;
    }
}

@include eureka-breakpoint-mobile {
    .web-chat-window-container {
        max-width: $mobile-chat-full-screen-max-width;
        right: 0;

        &__tile.eureka-tile {
            @include eureka-scroll-x;
            max-width: $mobile-chat-full-screen-max-width;
            height: 100%;
        }
    }
}

@include eureka-breakpoint-mobile(landscape) {
    .web-chat-window-container {
        max-width: $mobile-chat-full-screen-max-height;
        position: fixed;
        right: 0;

        &__tile.eureka-tile {
            @include eureka-scroll-y;
            max-width: $mobile-chat-full-screen-max-height;
            height: 100%;
            max-height: $mobile-chat-full-screen-max-width;
        }
    }
}
