@import '~@Green-Dot-Corporation/eureka-lib-style-utils/src/styles/mixins/layout.scss';
@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/utils/theme-utils.scss';
@import '../../../styles/mixin/layout.scss';

// modal scss
.web-chat-modal-wrapper {
    box-shadow: 0 0 20px rgba(0, 0, 0, 40%);
    text-align: center;
    $this: &;

    &__overlay.eureka-modal__overlay {
        @include eureka-flex-column;
        align-items: center;
        background-color:
            var(
                --eureka-modal-overlay-color-bg,
                rgba(0, 0, 0, 50%)
            );
        border-radius: var(--eureka-tile-border-radius, #{eureka-light-theme('tile-border-radius')});
        cursor: initial;
        max-width: 376px;
        bottom: 0;
        right: 0;
        left: unset;
        top: unset;
        position: unset;

        .eureka-modal {
            height: auto;
            max-width: 343px;

            &__main-container {
                padding-bottom: 0;
            }

            &__actions-container-inner {
                margin: 0;
            }

            &__actions-container {
                justify-content: center;
                margin-top:
                    #{eureka-to-px(
                        eureka-light-theme('core-system-scale') * 6
                    )};
            }

            #{$this}__btn--single.eureka-button {
                width: 311px;
            }
        }
    }

    .eureka-callout {
        padding: 0;
    }

    .eureka-icon {
        width: 53px;
        height: 53px;
    }

    &__icon--primary .eureka-icon {
        color: var(--eureka-core-color-primary, #{eureka-light-theme('core-color-primary')});
    }

    &__icon--error .eureka-icon {
        color: var(--eureka-core-color-destructive, #{eureka-light-theme('core-color-destructive')});
    }

    .eureka-callout__title-container .eureka-heading--h2 {
        font-size: var(--eureka-core-typography-headings-level5-font-size, #{eureka-to-rem(eureka-light-theme('core-typography-headings-level5-font-size'))});
    }

    .eureka-button {
        // Fix safari button issue
        // https://www.google.com/search?q=ios++button+margin+2px+site:github.com&rlz=1C1ONGR_enUS989US989&sa=X&ved=2ahUKEwjmlLb1rMH7AhVAJEQIHaIHC2wQrQIoA3oECBQQBA&biw=2317&bih=1221&dpr=0.8
        margin: 0;

        &__content-container {
            padding: 0.8rem 0 0.9rem;
        }

        &__text-container {
            padding: 0;
        }
    }
}

.react-modal-portal {
    z-index: 999;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 376px;
    height: 100%;
}

@include eureka-breakpoint-mobile {
    .react-modal-portal,
    .web-chat-modal-wrapper__overlay.eureka-modal__overlay {
        max-width: $mobile-chat-full-screen-max-width;
        border-radius: 0;
    }
}

@include eureka-breakpoint-mobile(landscape) {
    .react-modal-portal,
    .web-chat-modal-wrapper__overlay.eureka-modal__overlay {
        max-width: $mobile-chat-full-screen-max-height;
        border-radius: 0;
    }
}
