@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/mixins/states.scss';
@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/utils/theme-utils.scss';

.web-chat-message-input {
    width: 100%;
    line-height: 2.5rem;
    padding: 0.4rem 0.3rem 0.2rem 1.2rem;
    resize: none;
    caret-color: var(--eureka-form-input-color-text, #{eureka-light-theme('form-input-color-text')});
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */

    &:focus {
        outline: none;
    }
}

.web-chat-window__message-input .eureka-floating-label-composer__after-comp-container {
    width: 20%;
}

.web-chat-window__message-input .eureka-floating-label-composer__content-container {
    align-items: flex-end;
}
