:root {
    --chat-bubble-max-width: 266px;
}

.web-chat-module {
    button {
        font-size: 100%;
    }

    .web-chat-leave-message {
        input {
            caret-color: auto;
        }
    }
}
