// chat window scss
@import '~@Green-Dot-Corporation/eureka-lib-style-utils/src/styles/mixins/layout.scss';
@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/utils/theme-utils.scss';

.web-chat-window {
    $this: &;

    &__chat-window-container {
        height: 406px;
    }

    &__messages-container {
        @include eureka-scroll-y;
    }

    &__chat-text-container {
        @include eureka-flex-column;
        flex: 1;
        height: 100%;
    }

    .eureka-tile__content-container-inner {
        @include eureka-flex-row;
        align-items: center;
        height: 100%;
    }

    &__send-message-container {
        margin-top: auto;

        ::-webkit-scrollbar {
            width: 0;
        }

        ::-webkit-scrollbar-track {
            opacity: 0;
        }

        ::-webkit-scrollbar-thumb {
            opacity: 0;
        }

        .web-chat-window__message-input {
            .eureka-floating-label-composer__after-comp-container {
                height: 2.5rem;
                margin: 5px;
            }
        }

        .web-chat-window__message-input.eureka-floating-label-composer {
            display: block;
        }
    }

    &__counter-container {
        font-size: var(--eureka-core-typography-footnote-font-size, #{eureka-to-rem(eureka-light-theme('core-typography-footnote-font-size'))});
        margin-bottom: 5px;
    }

    &__actions-container {
        @include eureka-flex-row;
        #{$this}__attach-btn {
            .eureka-file-picker-button__button {
                color: var(--eureka-btn-primary-color-border, #{eureka-light-theme('btn-primary-color-border')});
            }

            .eureka-file-picker-button__content-container {
                padding: 0;
            }

            .eureka-button__icon {
                height:
                    #{eureka-to-px(
                        eureka-light-theme('core-system-scale') * 6
                    )};
            }

            .eureka-file-picker-button__input:enabled + .eureka-file-picker-button__button:hover {
                background-color: var(--eureka-core-color-canvas, #{eureka-light-theme('core-color-canvas')});
                border-color: var(--eureka-btn-primary-color-border, #{eureka-light-theme('btn-primary-color-border')});
                color: var(--eureka-btn-primary-color-border, #{eureka-light-theme('btn-primary-color-border')});
            }
        }

        #{$this}__send-btn {
            width: 25px;
            height: 25px;
            border-radius: var(--eureka-chat-floating-btn-border-radius, #{eureka-light-theme('chat-floating-btn-border-radius')});
            margin: auto;

            .eureka-button__icon-safe-space {
                display: inline-flex;
                vertical-align: unset;
            }

            .eureka-icon {
                height: 2.4rem;
            }
        }

        .eureka-button__content-container,
        .eureka-button__icon-container {
            padding: 0;
        }
    }

    &__attach-btn {
        margin-right:
            #{eureka-to-px(
                eureka-light-theme('core-system-scale') * 4
            )};
        overflow: hidden;
    }

    &__after-control-container {
        @include eureka-flex-column;
        justify-content: flex-end;
        flex: 0 0 auto;
        align-items: flex-end;
        position: absolute;
        bottom: -5px;
        right: 0;
        margin-right: 3px;
        margin-bottom: 2px;
    }
}
