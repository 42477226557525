@import '~@Green-Dot-Corporation/eureka-lib-style-utils/src/styles/mixins/layout.scss';
@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/utils/theme-utils.scss';

.web-chat-hoop {
    $this: &;

    #{$this}__hoop {
        .eureka-tile__content-container {
            @include eureka-flex-row;
            justify-content: center;
            align-items: center;
        }
    }

    &__hoop-content {
        .eureka-callout {
            padding: 0;

            &__title {
                font-size:
                    var(
                        --eureka-core-typography-headings-level5-font-size
                    );
            }
        }
    }

    &__leave-message-btn {
        width: 100%;
        margin-top: #{eureka-to-px(eureka-light-theme('core-system-scale') * 4)};
    }
}
