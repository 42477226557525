// TODO: The code should be moved in ~@Green-Dot-Corporation/eureka-lib-style-utils/src/styles/mixins/layout.scss file.
$mobile-chat-full-screen-max-width: 455px; // 375 chat window + 80 marign right
$mobile-chat-full-screen-max-height: 932px;

@mixin eureka-breakpoint-mobile($orientation: portrait) {
    @if $orientation == portrait {
        @include eureka-breakpoint-portrait {
            @media screen and (max-width: $mobile-chat-full-screen-max-width) and (max-height: $mobile-chat-full-screen-max-height) {
                @content;
            }
        }
    } @else {
        @include eureka-breakpoint-landscape {
            @media screen and (max-width: $mobile-chat-full-screen-max-height) and (max-height: $mobile-chat-full-screen-max-width) {
                @content;
            }
        }
    }
}

@mixin eureka-breakpoint-landscape() {
    @media (orientation: landscape) {
        @content;
    }
}

@mixin eureka-breakpoint-portrait() {
    @media (orientation: portrait) {
        @content;
    }
}
