// choose option window scss
@import '~@Green-Dot-Corporation/eureka-lib-style-utils/src/styles/mixins/layout.scss';
@import '~@Green-Dot-Corporation/eureka-lib-style-utils/src/styles/mixins/resets.scss';
@import '~@Green-Dot-Corporation/eureka-lib-theme-utils/src/styles/utils/theme-utils.scss';

.web-chat-choose-option-window {
    &__header-container {
        margin-bottom:
            #{eureka-to-px(
                eureka-light-theme('core-system-scale') * 4
            )};
        padding: 0;

        .eureka-callout__title {
            font-size: var(--eureka-core-typography-headings-level5-font-size, #{eureka-to-rem(eureka-light-theme('core-typography-headings-level5-font-size'))});
        }

        .eureka-callout__inner {
            text-align: left;
        }

        .eureka-list,
        %eureka-list {
            @include eureka-list-reset;

            &__li + &__li {
                border-top: var(--eureka-list-border-width, #{eureka-light-theme('list-border-width')}) solid #ccc;
            }
        }
    }

    &__content-container {
        border: var(--eureka-list-border-width, #{eureka-light-theme('list-border-width')}) solid #ccc;
        border-radius: var(--eureka-chat-window-border-radius, 8px);

        .eureka-list-item {
            background-color: unset;
        }
    }

    &__svg-container {
        margin-top: 3px;
    }
}
